import React, { Component } from 'react';
import { connect } from "react-redux";
import Header from './Header/Header';
import Footer from './Footer/Footer';


import SearchInput from "./SearchInput/SearchInput";
import SetSelector from './SetSelector/SetSelector';
import './SideBarContent.css';

import {
    action_submit_search_term,
    action_upload_image,
    action_show_similar_images,
    action_similar_images,
    action_filter_color,
    action_filter_license,
    action_change_filters,
    action_filter_color_processing
  } from '../../Modules/redux/actions';

import withStyles from "@material-ui/core/styles/withStyles";


const styles = () => ({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0
    },
    sideBarContent: {
        flexGrow: 1,
        overflowY: "auto",
        overflowX: "hidden",
        padding: '0px 5px 0px 5px',
        "& > webkitScrollbarThumb": {
            background: "#888",
            borderRadius: "10px",
            width: "5px",
        },
        "& > scrollbar-track":
        {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
            backgroundColor: "#F0F0F0"
        },
        "& > scrollbar":
        {
            width: "6px",
            "backgroundColor": "#F0F0F0"
        }
    }
});



class SideBarContent extends Component {

    constructor(props){
        super(props);
        this.state = {
            searchText: ""
        }

        this.resetSearch = this.resetSearch.bind(this);
        this.setSearchText = this.setSearchText.bind(this);
    }

    resetSearch(filtersOnly=false){
        
        
        this.props.dispatch(action_filter_color([]));
        this.props.dispatch(action_change_filters([]));
        this.props.dispatch(action_filter_license([]));
        if (filtersOnly){
            this.setState({ searchText: "" })
            this.props.dispatch(action_similar_images([]));
            this.props.dispatch(action_submit_search_term(""));
            this.props.dispatch(action_upload_image(""));
            this.props.dispatch(action_show_similar_images(false));
        }
        
    };

    setSearchText(text){
        this.setState({ searchText: text })
    }

    render() {
        return (
            <div className={this.props.classes.mainContainer}>
                <Header theme={this.props.theme} />

                <div id='sbc' className={this.props.classes.sideBarContent}>
                    <SetSelector resetSearch={this.resetSearch}/>
                    <SearchInput theme={this.props.theme} searchText={this.state.searchText} setSearchText={this.setSearchText} resetSearch={this.resetSearch}/>
                </div>

                <Footer theme={this.props.theme} />
            </div>

        );
    }
}

export default connect()(withStyles(styles)(SideBarContent));
