import React from 'react'
import { connect } from "react-redux";
import { IMAGE_BASE_URL, DELETED_IMAGE_URL } from '../../../config'

import { ImageList as GridList } from '@material-ui/core'
import { ImageListItem as GridListTile } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import getEventBus from "../../../lib/ImageSphereEventBus";
import styleTheme from '../../../styleTheme.js';
import { createGraphURLPart } from "../../../API/jsonAPI"

const mainColor = styleTheme.palette.secondary.dark

class SimilarImagesBox extends React.Component {

  constructor(props) {
    super(props);
    window.SimilarImagesBox = this;
    this.eventBus = getEventBus();
    this.state = {
      activeImageId: -1,
      isOpen: false,
      newSearch: false
    }
  }

  /**
   * @override
   */
  componentDidMount = () => {
    const self = this;
    this.imageClickedId = this.eventBus.subscribe('ImageClicked', function (obj) {
      if(obj.mode == "single"){
        self.setState({ isOpen: false });
      }
    });
    this.backToResultsId = this.eventBus.subscribe('BackToResults', function () {
      self.setState({ isOpen: true });
    });

    this.eventBus.subscribe('FiltersOpened', function (areFiltersOpen) {
      // self.setState({
      //   isOpen: areFiltersOpen && self.state.image
      // });

      self.setState({ isOpen: !areFiltersOpen });

    });

  };

  /**
   * @override
   */
  componentWillUnmount = () => {
    this.eventBus.unsubscribe('ImageClicked', this.imageClickedId);
    this.eventBus.unsubscribe('BackToResults', this.backToResultsId);
    this.eventBus.unsubscribe('FiltersOpened', this.imageClickedId);
  };

  /**
   * @override
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {

    if ((this.props.similarImages === prevProps.similarImages)) return;

    if (this.props.similarImages !== prevProps.similarImages && this.state.activeImageId !== -1) {
      this.setState({ "activeImageId": -1 });
    }

    if (this.props.similarImages[0]) {

      this.setState({ "isOpen": true });
      this.newSearch = true;
      const imageId = "imageTile-" + this.props.similarImages[0].id;
      if (!this.props.similarImages[0].skipAnimation) {
        setTimeout(() => {
          let firstImage = document.getElementById(imageId);
          if (firstImage)
            firstImage.click();
        }, 500);
      }
    }
  }

  getSimilarImagesPaths(similarImages) {

    if (similarImages) {

      if (this.props.chosenGraph === -1) return
      const graphURL = createGraphURLPart()

      return similarImages.map((imageData) => {

        let path = imageData.id === -1 ? DELETED_IMAGE_URL : `${IMAGE_BASE_URL}/${graphURL}/thumbs/crop/${imageData.path}`
        path = path.split('.').slice(0, -1).join('.') + ".jpg"
        return {
          path: path,
          id: imageData.id
        }

      })
    } else return [];
  }

  onImageTileClick(imageID, filename) {
    if (imageID && !window.Animator.animationRunning) {

      const image = document.getElementById("imageTile-" + imageID).cloneNode(true);
      const rect = document.getElementById("imageTile-" + imageID).getBoundingClientRect();
      image.style.border = "None";

      this.setState({ "activeImageId": parseInt(image.id.split("-")[1]) });

      if (this.newSearch)
        this.newSearch = false;

      window.Animator.findSimilar(image, {
        top: rect.top + rect.height / 2,
        left: rect.left,
        width: rect.width,
        height: rect.height
      }, { reduceTargetTopByHalfHeight: true }); //,  animatorCallback, cbArgs

      window.CanvasContainer.updateCentralImage(filename, 0, imageID);
    }
  }

  getImageTileClass(id, index) {
    // if no tile has been clicked mark the first one as active
    if (this.state.activeImageId === id || (this.state.activeImageId === -1 && index === 0))
      return this.props.classes.activeTile;
    else
      return this.props.classes.imgTile
  }

  render() {
    if (this.props.chosenGraph === -1 || !this.state.isOpen) return (<div></div>)
    else {

      const similarImagesPaths = this.getSimilarImagesPaths(this.props.similarImages);
      // console.log("smb", this.state.isOpen)
      return (
        <div>
          {this.state.isOpen &&
            <div className={this.props.classes.container}>
              <GridList rowHeight={'auto'} cols={3}>
                {similarImagesPaths.map((tile, index) => (
                  <GridListTile key={"key-" + index}>
                    <img id={"imageTile-" + tile.id} className={this.getImageTileClass(tile.id, index)} src={tile.path} alt={tile.path} onClick={() =>
                      this.onImageTileClick(tile.id, this.props.similarImages[index].path)} />
                  </GridListTile>
                ))}
              </GridList>
            </div>
          }
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    color: state.filter.color,
    similarImages: state.search.similarImages,
    chosenGraph: state.settings.chosenGraph,
  }
}

const styles = ({
  imgTile: {
    // objectFit: "cover",
    width: "100%",
    // height: "11vh",
    cursor: 'pointer'
  },
  activeTile: {
    // objectFit: "cover",
    width: "100%",
    // height: "11vh",
    borderStyle: "solid",
    borderWidth: "5px",
    borderColor: mainColor,
    cursor: 'pointer'
  },
  container: {
    margin: '20px 12px 20px 12px',
    paddingBottom: '12px'
  }
});

export default connect(mapStateToProps)(withStyles(styles)(SimilarImagesBox));
